import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

export const CardSkills = () => {
  return (
    <Container>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 2, mb: 3 }}
      >
        <Typography sx={{ p: 1 }}>EXPERTISE</Typography>
        <Typography sx={{ pb: 1 }} variant="body2">
          5 + years of experience with tech startups & boards
        </Typography>
      </Stack>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mb: 6 }}
      >
        <Card
          sx={{
            minWidth: 275,
            boxShadow: "2px 2px 10px rgb(0 0 0 / 0.2)",
          }}
          elevation={0}
        >
          <CardContent>
            <Typography sx={{ textAlign: "center" }}>
              Web Development
            </Typography>
            <ul>
              <li>
                <Typography variant="body2">React, MUI, JS, TS</Typography>
              </li>
              <li>
                <Typography variant="body2">UX/UI with Figma</Typography>
              </li>
              <li>
                <Typography variant="body2">Prototype release</Typography>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card
          sx={{ minWidth: 275, boxShadow: "2px 2px 10px rgb(0 0 0 / 0.2)" }}
          elevation={0}
        >
          <CardContent>
            <Typography sx={{ textAlign: "center" }}>
              Business development
            </Typography>
            <ul>
              <li>
                <Typography variant="body2">Team management & HR</Typography>
              </li>
              <li>
                <Typography variant="body2">B2B Sales</Typography>
              </li>
              <li>
                <Typography variant="body2">Project management</Typography>
              </li>
            </ul>
          </CardContent>
        </Card>
        <Card
          sx={{ minWidth: 275, boxShadow: "2px 2px 10px rgb(0 0 0 / 0.2)" }}
          elevation={0}
        >
          <CardContent>
            <Typography sx={{ textAlign: "center" }}>Governance</Typography>
            <ul>
              <li>
                <Typography variant="body2">Strategic planning</Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Board structure & regulation
                </Typography>
              </li>
              <li>
                <Typography variant="body2">Project evaluation</Typography>
              </li>
            </ul>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  );
}
