import { articles } from '../data/thoughts'
import { useParams } from 'react-router-dom'
import { Container, Typography, Box, Chip, Stack, Button } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Link } from "react-router-dom";

export const ThoughtsView = () => {
  const { id } = useParams()
  const article = articles.find(a => a.id === id)
  if (!article) {
    // return <Redirect to="/thoughts"/>
  }
  return (
    <Container>
      {/* <>{JSON.stringify(article)}</> */}
      <Button
        component={Link}
        size="medium"
        startIcon={<ArrowBackIosNewIcon />}
        to="/thoughts"
      >
        Back
      </Button>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Typography variant="h5" sx={{ maxWidth: 700, textAlign: "center", my: 2 }}>
          {article.title}
        </Typography>
        <Typography color="darkGrey" variant="body2">
          {" "}
          Published on {article.date} by {article.author}
        </Typography>
        <Box sx={{ mt: 1 }}>
          {article.tags?.map((tag) => (
            <Chip
              label={tag}
              size="small"
              variant="outlined"
              sx={{ mt: 0.5, mr: 0.5, color: "darkGrey" }}
            />
          ))}
        </Box>
        <Box sx={{ mt: 2 }}>
          <img
            src={article.image}
            alt={article.title}
            style={{ width: 600, objectFit: "cover" }}
          />
        </Box>
        <Box>
          <Typography>{article.content}</Typography>
        </Box>
      </Stack>
    </Container>
  );
};
