import { Typography, Container, Link } from "@mui/material"

export const Top3OnlineClasses = () => {
  return (
    <Container sx={{ mx: 3, mt: 4, mb: 8 }}>
      <Typography sx={{ pb: 2 }}>
        Hello there,
        <br />
        I have jumped into software development in 2021 and before taking a 100%
        time commitment engagement to learn how to code, I’ve decided to test my
        interests in coding through a few websites and programs.
        <br />
        <br />
        Here is my honest review of what I tried including the pros and cons of
        each of the platforms.
        <br />
        <br />
        My top 3 platforms :
      </Typography>
      <Typography
        sx={{ pb: 2 }}
        component={Link}
        href="https://www.edx.org/"
        target="_blank"
        variant="h6"
      >
        1. edX
      </Typography>
      <Typography sx={{ pb: 2 }}>
        It's a mix of videos, reading, and exercises! I thought the lesson was
        very well explained. Huge pros: They were putting a strong basis t to
        learn coding by explaining the WHY we write code like this and detailed
        explanations regarding the subtleties of the code to make it easier to
        build on that later.
        <br />
        <br />
        You have the choice to take the free path or the paid one to get a
        verified certificate. It's few hours a week with milestones to achieve.
        You'll have access to the course and platform for a length of time (+- 6
        weeks for e.g.).
        <br />
        <br />
        Here's the course I took:{" "}
        <Link
          href="https://www.edx.org/course/html5-and-css-fundamentals"
          target="_blank"
        >
          HTML5 and CSS Fundamentals{" "}
        </Link>{" "}
        (free path)
        <br />
        You can find plenty of other computer science courses on edX{" "}
        <Link
          href="https://www.edx.org/course/subject/computer-science"
          target="_blank"
        >
          {" "}
          here
        </Link>
        .
      </Typography>
      <Typography
        sx={{ pb: 2 }}
        component={Link}
        href="https://www.lewagon.com"
        target="_blank"
        variant="h6"
      >
        2. Le Wagon
      </Typography>
      <Typography sx={{ pb: 2 }}>
        You have to subscribe to the newsletter of Le Wagon! You'll have access
        to plenty of free events and workshops. Discover their{" "}
        <Link href="https://www.lewagon.com/webinars" target="_blank">
          {" "}
          webinars here
        </Link>{" "}
        (+ subscribe to the newsletter at the bottom of the page). Small hack:
        change the campus (eg. Montreal, London, or else) to have access to more
        events!
        <br />
        <br />
        Here are a few e.g. of workshop subjects (every session is about 3 hours
        - Including the practice time with exercises on their platform at the
        end), So if you decide to attend one of the workshops, you'll have a
        course of about (+-1 or 2 hours):
        <ul>
          <li>
            Free Workshop Web dev Academy (HTML,CSS, JS) (2 hours for 3 days in
            one week){" "}
          </li>
          <li>Learn how to query databases with SQL (1 hour) </li>
          <li> Learn how to automate tasks with APIs (1.5 hours) </li>
          <li>Data Analytics with Python (2 hours)</li>
        </ul>
        (p.s. I took all of these to have them on my platform)
      </Typography>
      <Typography sx={{ pb: 2 }}>
        Small hack if you don't have time to watch the whole session: Just login
        and let it run to get the link at the end of the course to practice &
        learn on Le Wagon free platform. Huge pros: After each webinar, you have
        LIFETIME access to Le Wagon free platform to do exercises and have
        access to the recorded session. I just really like the concept of this
        and you can go at your pace! + login with the same account each time and
        many courses build up and you have a full book of classes under your
        name! GREAT!
      </Typography>
      <Typography
        sx={{ pb: 2 }}
        component={Link}
        href="https://www.codecademy.com"
        target="_blank"
        variant="h6"
      >
        3. Codeacademy
      </Typography>
      <Typography>
        The is such a large variety of courses to do at your own pace, so no
        deadlines! You'll create your profile to have access to plenty of
        courses and track your progress.
        <br />
        <br />
        Pros: I thought the interactive interface with the console is amazing!
        Having everything on the same screen is just great! As the concept is
        like to follow a book, you have your progress at the bottom of each
        page, so it's a little motivator to add!
        <br />
        <br />
        Cons: My only complaint would be that there are no videos. So it's only
        reading + exercises, and to be honest, I didn't find the reading very
        well explain (like edX). It was more a lot of short reading and plenty
        of exercises, so I thought it was missing context for me to really
        understand what I was coding.
        <br />
        <br />
        Here's the course I took:{" "}
        <Link href="https://www.codecademy.com/learn/learn-ruby" target="blank">
          Learn Ruby
        </Link>. They also have plenty of other courses{" "}
        <Link href="https://www.codecademy.com/catalog" target="_blank">here</Link>.
      </Typography>
    </Container>
  );
}
