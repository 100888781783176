import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link"
import { Link as LinkReact } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";


const pages = [
  // {
  //   name: "Projects",
  //   route: "/projects",
  // },
  {
    name: "Readings",
    route: "/readings",
  },
  {
    name: "Thoughts",
    route: "/thoughts",
  },
];

const socialLinks = [
  {
    icon: <LinkedInIcon />,
    link: "https://www.linkedin.com/in/roxannelessard/",
  },
  {
    icon: <GitHubIcon />,
    link: "https://github.com/roxannelessard",
  },
  {
    icon: <InstagramIcon />,
    link: "https://www.instagram.com/lessard.roxanne/",
  },
];

export const ResponsiveAppBar = () => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Button
            noWrap
            variant="h6"
            component={LinkReact}
            to={"/"}
            sx={{
              mr: 4,
              textTransform: "none",
              fontSize: "18px",
              fontWeight: "bolder",
              display: { xs: "none", md: "flex" },
            }}
          >
            roxannelessard
          </Button>

          <Button
            noWrap
            variant="h6"
            component={LinkReact}
            to={"/"}
            sx={{
              mr: 4,
              textTransform: "none",
              fontSize: "18px",
              fontWeight: "bolder",
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            roxannelessard
          </Button>

          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                sx={{ my: 2, color: "black", display: "block" }}
                component={LinkReact}
                to={page.route}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
            <>
              <Box sx={{ pl: 18 }}>
                <Button onClick={handleClick} color="inherit">
                  <MenuIcon />
                </Button>
              </Box>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {pages.map((page) => (
                  <MenuItem
                    key={page.name}
                    component={LinkReact}
                    to={page.route}
                    onClick={handleClose}
                  >
                    {page.name}
                  </MenuItem>
                ))}
                <MenuItem>
                  {socialLinks.map((socialLink) => (
                    <IconButton
                      key={socialLink.name}
                      component={Link}
                      href={socialLink.link}
                      target="_blank"
                      sx={{
                        height: 40,
                        color: "black",
                        display: "block",
                      }}
                      onClick={handleClose}
                    >
                      {socialLink.icon}
                    </IconButton>
                  ))}
                </MenuItem>
              </Menu>
            </>
          </Box>

          <Box sx={{ textAlign: "right", display: { xs: "none", md: "flex" } }}>
            {socialLinks.map((socialLink) => (
              <IconButton
                component={Link}
                href={socialLink.link}
                key={socialLink.name}
                target="_blank"
                sx={{ height: 40, my: 2, color: "black", display: "block" }}
              >
                {socialLink.icon}
              </IconButton>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
