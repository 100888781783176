import './App.css';
import { ResponsiveAppBar } from "./components/Navbar";
import { Readings } from "./pages/Readings";
import { Thoughts } from "./pages/Thoughts";
import { Projects } from "./pages/Projects";
import { Home } from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import { ThoughtsView } from './pages/ThoughtsView';

function ExternalRedirect(props) {
  window.location.replace(props.to)
  return null
}

function App() {
  return (
    <>
      <ResponsiveAppBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/readings" element={<Readings />} />
        <Route exact path="/thoughts" element={<Thoughts />} />
        <Route path="/thoughts/:id" element={<ThoughtsView />} />
        <Route
          path="/30min-meeting"
          element={
            <ExternalRedirect to="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1powhLtNWVSWsqc3MSdW8bSBYwoRZnjNTYERGwJXhDPAq5mZENljgyY_-TCzIBCTbFzt7T-hm8?gv=true" />
          }
        />
        <Route
          path="/1hour-meeting"
          element={
            <ExternalRedirect to="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1tPapnLK6Ngj44Dg_tkauo2NUHXQqvyBzIWCEMU8HOzB500Lj5XsMPFEExBZJ88tCZOwNgXKgY?gv=true" />
          }
        />
        <Route
          path="/book-me"
          element={
            <ExternalRedirect to="https://calendar.app.google/4XR8PqJofMA9zPM79" />
          }
        />
      </Routes>
    </>
  );
}

export default App;
