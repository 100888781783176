import * as React from "react";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";

export const Rating = (props) => {
      if (props.ratingValue === 1) {
        return (
          <Box sx={{ mt: 1 }}>
            <StarIcon fontSize="small" />
            <StarIcon fontSize="small" color="disabled" />
            <StarIcon fontSize="small" color="disabled" />
            <StarIcon fontSize="small" color="disabled" />
            <StarIcon fontSize="small" color="disabled" />
          </Box>
        );
      }
      if (props.ratingValue === 2) {
        return (
          <Box sx={{ mt: 1 }}>
            <StarIcon fontSize="small" />
            <StarIcon fontSize="small" />
            <StarIcon fontSize="small" color="disabled" />
            <StarIcon fontSize="small" color="disabled" />
            <StarIcon fontSize="small" color="disabled" />
          </Box>
        );
      }
      if (props.ratingValue === 3) {
        return (
          <Box sx={{ mt: 1 }}>
            <StarIcon fontSize="small" />
            <StarIcon fontSize="small" />
            <StarIcon fontSize="small" />
            <StarIcon fontSize="small" color="disabled" />
            <StarIcon fontSize="small" color="disabled" />
          </Box>
        );
      }
      if (props.ratingValue === 4) {
        return (
          <Box sx={{ mt: 1 }}>
            <StarIcon fontSize="small" />
            <StarIcon fontSize="small" />
            <StarIcon fontSize="small" />
            <StarIcon fontSize="small" />
            <StarIcon fontSize="small" color="disabled" />
          </Box>
        );
      }
      if (props.ratingValue === 5) {
        return (
          <Box sx={{ mt: 1 }}>
            <StarIcon fontSize="small" />
            <StarIcon fontSize="small" />
            <StarIcon fontSize="small" />
            <StarIcon fontSize="small" />
            <StarIcon fontSize="small" />
          </Box>
        );
      }
      return props.ratingValue;
};
