export const projects = [
  {
    id: "lawy",
    name: "Lawy",
    type: "web development",
    firstReleaseDate: "2022-04-06",
    logo: "NA",
    appImage: "N/A",
    websiteUrl: "https://lawy.co/",
    appUrl: "https://vaults.lawy.co/",
    sponsor: "iwn studio",
    tags: ["Legal", "Documents management"],
    description:
      "Securely store, organize and access legal documents into your private vault.",
    contributors: [
      {
        photo: "NA",
        name: "Roxanne Lessard",
        linkedIn: "https://www.linkedin.com/in/roxannelessard/",
      },
      {
        photo: "NA",
        name: "Raff Paquin",
        linkedIn: "https://www.linkedin.com/in/raffp/",
      },
      {
        photo: "NA",
        name: "Natalia Targowska",
        linkedIn: "https://www.linkedin.com/in/natalia-targowska-a70709a6/",
      },
    ],
  },
  {
    id: "hoopback",
    name: "Hoopback",
    type: "web development",
    firstReleaseDate: "2022-04-06",
    logo: "NA",
    appImage: "N/A",
    websiteUrl: "https://hoopback.co/",
    appUrl: "https://app.hoopback.co/",
    sponsor: "iwn studio",
    tags: ["Feedback", "Customer support"],
    description: "blabla.",
    contributors: [
      {
        photo: "NA",
        name: "Roxanne Lessard",
        linkedIn: "https://www.linkedin.com/in/roxannelessard/",
      },
      {
        photo: "NA",
        name: "Raff Paquin",
        linkedIn: "https://www.linkedin.com/in/raffp/",
      },
      {
        photo: "NA",
        name: "Natalia Targowska",
        linkedIn: "https://www.linkedin.com/in/natalia-targowska-a70709a6/",
      },
    ],
  },
];
