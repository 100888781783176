import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { Rating } from '../components/Rating';
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import readings from '../data/readings.json';
import Fuse from "fuse.js";

const fuse = new Fuse(readings, {
  keys: ["title", "author", "tags", "rating"],
  minMatchCharLength: 2,
});

const defaultBookList = fuse.getIndex().docs.map((item) => ({ item }));

export const Readings = () => {
  const [books, setBooks] = useState(defaultBookList);
  // to store the userInput value we need a state
  const [userInput, setUserInput] = useState("");

  // we use useEffect to run code when useInput change
  // here we run the search with the user input and set the results of the search in the state books
  useEffect(() => {
    if (userInput === "") {
      setBooks(defaultBookList)
    } else {
    setBooks(fuse.search(userInput));
    }
  }, [userInput]);

  return (
    <Container sx={{ mt: 6 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Readings
      </Typography>

      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 4 }}
      >
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Books & articles I recommend
        </Typography>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            boxShadow: "3px 3px 8px rgb(0 0 0 / 0.2)",
            display: "flex",
            alignItems: "center",
            width: 350,
          }}
          elevation={0}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            onChange={(e) => setUserInput(e.currentTarget.value)}
          />
          <IconButton type="submit" sx={{ p: "10px" }}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </Stack>
      {/* {JSON.stringify(userInput)} */}
      <Grid container alignItems="stretch" spacing={2}>
        {books.map(({ item: book }) => (
          <Grid item key={book.title} xs={12} sm={6} md={6} lg={4}>
            <Card elevation={0} sx={{ height: "100%" }}>
              <CardActionArea
                href={book.url}
                target="_blank"
                sx={{ height: "100%" }}
              >
                <Stack direction="row" sx={{ padding: 1 }}>
                  <CardMedia
                    component="img"
                    sx={{
                      width: 151,
                      objectFit: "cover",
                      boxShadow: "3px 3px 8px rgb(0 0 0 / 0.2)",
                      borderRadius: "4px",
                    }}
                    image={book.image}
                    alt={book.title}
                  />
                  <CardContent>
                    <Typography sx={{ mb: 1, fontSize: "14px" }}>
                      {book.title}
                    </Typography>
                    <Typography color="darkGrey" variant="caption">
                      By {book.author}
                    </Typography>
                    <Box>
                      <Rating ratingValue={book.rating} />
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      {book.tags?.map((tag) => (
                        <Chip
                          label={tag}
                          size="small"
                          variant="outlined"
                          sx={{ mt: 0.5, mr: 0.5, color: "darkGrey" }}
                        />
                      ))}
                    </Box>
                  </CardContent>
                </Stack>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
