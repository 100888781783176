import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import { articles } from '../data/thoughts';
import { Button, CardActionArea, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
// import SearchIcon from "@mui/icons-material/Search";
// import InputBase from "@mui/material/InputBase";
// import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from 'react-router-dom';

export const Thoughts = () => {
  return (
    <Container sx={{ mt: 6 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Thoughts
      </Typography>

      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ md: "space-between" }}
        alignItems="center"
        sx={{ mb: 4 }}
      >
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Some of my latests thoughts & favourite articles
        </Typography>
        {/* <Paper
          component="form"
          sx={{
            p: "2px 4px",
            boxShadow: "3px 3px 8px rgb(0 0 0 / 0.2)",
            display: "flex",
            alignItems: "center",
            width: 350,
          }}
          elevation={0}
        >
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" />
          <IconButton type="submit" sx={{ p: "10px" }}>
            <SearchIcon />
          </IconButton>
        </Paper> */}
      </Stack>
      <Grid container alignItems="stretch" spacing={2}>
        {articles.map((article) => (
          <Grid item key={article.title} xs={12}>
            <Card elevation={0} sx={{ height: "100%" }}>
              <CardActionArea
                component={Link}
                to={`/thoughts/${article.id}`}
                sx={{ height: "100%" }}
              >
                <Stack direction="row" sx={{ padding: 1 }}>
                  {/* <CardMedia
                    component="img"
                    sx={{
                      width: 151,
                      objectFit: "cover",
                      boxShadow: "3px 3px 8px rgb(0 0 0 / 0.2)",
                      borderRadius: "4px",
                    }}
                    image={article.image}
                    alt={article.title}
                  /> */}
                  <CardContent sx={{ width: "100%" }}>
                    <Divider sx={{ mb: 3 }} textAlign="right">
                      <Typography>{article.date}</Typography>
                    </Divider>
                    <Typography sx={{ mb: 1, fontSize: "16px" }}>
                      {article.title}
                    </Typography>
                    <Typography
                      color="darkGrey"
                      variant="caption"
                      sx={{ mb: 1, fontSize: "14px" }}
                    >
                      By {article.author}
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      {article.tags?.map((tag) => (
                        <Chip
                          label={tag}
                          size="small"
                          variant="outlined"
                          sx={{ mt: 0.5, mr: 0.5, color: "darkGrey" }}
                        />
                      ))}
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <Button
                        size="small"
                        endIcon={<ArrowForwardIosIcon fontSize="small" />}
                        component={Link}
                        to={`/thoughts/${article.id}`}
                      >
                        Read
                      </Button>
                    </Box>
                  </CardContent>
                </Stack>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
