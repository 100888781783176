import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
// import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import { projects } from '../data/projects';
import { CardActionArea, Tooltip, Typography } from "@mui/material";
import { Link as LinkMUI } from "@mui/material";
import Chip from "@mui/material/Chip";
// import Divider from "@mui/material/Divider";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { Link } from 'react-router-dom';
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";

export const Projects = () => {
  return (
    <Container sx={{ mt: 6 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Projects
      </Typography>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ md: "space-between" }}
        alignItems="center"
        sx={{ mb: 4 }}
        nb
      >
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Some of my projects in web development, UX & design
        </Typography>
        {/* <Paper
          component="form"
          sx={{
            p: "2px 4px",
            boxShadow: "3px 3px 8px rgb(0 0 0 / 0.2)",
            display: "flex",
            alignItems: "center",
            width: 350,
          }}
          elevation={0}
        >
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" />
          <IconButton type="submit" sx={{ p: "10px" }}>
            <SearchIcon />
          </IconButton>
        </Paper> */}
      </Stack>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ md: "center" }}
        alignItems="center"
        spacing={4}
      >
        {projects.map((project) => (
          <Card>
            <CardActionArea
              component={LinkMUI}
              href={project.appUrl}
              target="_blank"
              sx={{ height: "100%" }}
            >
              <CardContent>
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Typography variant="h6">{project.name}</Typography>
                  <Typography
                    variant="caption"
                    component={LinkMUI}
                    href="www.iwn.studio"
                    target="_blank"
                    sx={{ textDecoration: "none", color: "darkgray" }}
                  >
                    by {project.sponsor}
                  </Typography>
                </Box>
                <Box sx={{ width: 600, height: 100, mt: 1 }}>
                  <Typography sx={{ maxWidth: 500, textAlign: 'center' }}>
                    {project.description}
                  </Typography>
                </Box>
                <Box sx={{ mb: 1 }}>
                  {project.tags.map((tag) => (
                    <Chip
                      label={tag}
                      size="small"
                      variant="outlined"
                      sx={{ mt: 0.5, mr: 0.5, color: "darkGrey" }}
                    />
                  ))}
                </Box>
                <AvatarGroup total={3}>
                  {project.contributors.map((people) => (
                    <Tooltip title={people.name}>
                      <Avatar
                        alt={people.name}
                        src="/static/images/avatar/1.jpg"
                        component={LinkMUI}
                        href={people.linkedIn}
                        sx={{ textDecoration: "none" }}
                      />
                    </Tooltip>
                  ))}
                </AvatarGroup>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Stack>
    </Container>
  );
};
