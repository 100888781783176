import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";

export const FormContact = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="text" onClick={handleClickOpen}>
        Say Hi 👋
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form
          data-netlify="true"
          name="contact"
          method="POST"
          action="/success"
        >
          <input type="hidden" name="form-name" value="contact" />
          <DialogTitle>Say Hi 👋</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
              Hey, I am glad to know you would like to talk. Please let me your
              email address here. If you prefer, feel free to book some time in
              my calendar{" "}
              <Link
                href="https://calendly.com/roxanne-iwn/10min-call"
                target="_blank"
              >
                here
              </Link>
              .
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
            />
            <TextField
              fullWidth
              name="content"
              label="Content"
              multiline
              rows={4}
              variant="standard"
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Send</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
