import { HomeCardRoxanne } from "../components/HomeCardRoxanne";
import { CardSkills } from "../components/CardSkills";

export const Home = () => {
  return (
    <>
      <HomeCardRoxanne />
      <CardSkills />
    </>
  );
};
