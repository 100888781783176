import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Avatar, Container, Stack, Link } from "@mui/material";
import { FormContact } from './FormContact';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export const HomeCardRoxanne = () => {
  return (
    <Container>
      <Card sx={{ minWidth: 275, mt: 5 }} elevation={0}>
        <CardContent>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            spacing={8}
          >
            <Box>
              <Avatar
                alt="Roxanne Lessard"
                src="/Roxanne-blue.png"
                sx={{
                  width: 250,
                  height: 250,
                  boxShadow: "3px 3px 8px rgb(0 0 0 / 0.2)",
                }}
              />
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {" "}
              </Typography>
              <Typography variant="h4" component="div">
                roxanne{bull}lessard
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Co-founder @{" "}
                <Link href="https://iwn.studio/" target="_blank">
                  iwn studio
                </Link>{" "}
                | UX developer
              </Typography>
              <Typography variant="body2">
                Hey I'm Roxanne.
                <br />
                Glad to have you here! Have a look around to discover some of my
                projects, articles and books reviews. <br />
                <br />
                Also, I'm currently building a new learning resources section if
                you're curious to learn software development just like I did.
              </Typography>
              <CardActions sx={{ padding: 0, mt: 3 }}>
                <FormContact />
              </CardActions>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
}
