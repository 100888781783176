import { Top3OnlineClasses } from './articles/Top3OnlineClasses';

export const articles = [
  {
    id: "top-3-online-classes",
    title:
      "The very start of my journey to learn how to code - My top 3 Free Online classes Review",
    author: "Roxanne Lessard",
    date: "2022-04-06",
    image:
      "https://res.cloudinary.com/dr5brdsjq/image/upload/v1650051814/christopher-gower-m_HRfLhgABo-unsplash_nteymb.jpg",
    tags: ["Web development", "Online Learning"],
    content: <Top3OnlineClasses/>,
  },
];
